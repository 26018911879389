import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
// import "@/components/vants";
import moment from "moment";
Vue.prototype.$moment = moment;
import "lib-flexible/flexible"; //自动根据屏幕宽度设置font-size
import "postcss-pxtorem"; //px转换rem
import "@/iconfont/iconfont.css";
import "@/permission.js";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
