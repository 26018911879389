import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
    },
    component: () => import("@/views/home"),
  },
  {
    path: "/tabPage",
    name: "tabPage",
    meta: {
      title: "产品信息",
    },
    component: () => import("@/views/tabPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
