//此文件会被引入到main.js项目启动时加载，所以可以在此文件做路由拦截
import router from "./router";
router.beforeEach(async (to, from, next) => {
  if (location.search) {
    var str = location.search.slice(1);
    var arr = str.split("&");
    var obj = {};
    arr.map((item) => {
      obj[item.split("=")[0]] = item.split("=")[1];
    });
    localStorage.setItem("params", JSON.stringify(obj));
  }
  //拦截加载的路由
  document.title = to.meta.title; // 设置浏览器的标题
  next();
});
